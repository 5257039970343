<template>
  <div class="content-container">
    <RequiredNutrientAlgorithm />
    <RequiredNutrientMapsSelection />
    <RequiredNutrientSurchargeDiscount />
  </div>
</template>

<script>
import RequiredNutrientAlgorithm from './RequiredNutrientAlgorithm'
import RequiredNutrientMapsSelection from './RequiredNutrientMapsSelection'
import RequiredNutrientSurchargeDiscount from './RequiredNutrientSurchargeDiscount'

export default {
  components: {
    RequiredNutrientAlgorithm,
    RequiredNutrientMapsSelection,
    RequiredNutrientSurchargeDiscount
  }
}
</script>

<style lang="scss" scoped>
</style>
