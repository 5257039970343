<template>
  <div>
    <FrsRouterBackLink :target="'phbb'">
      <template #text>
        <IxRes>phbb.buttons.backToPhbb</IxRes>
      </template>
    </FrsRouterBackLink>

    <ContentWrapper>
      <template #title>
        <IxRes>phbb.wizard.stepSixTitle</IxRes>
        <div class="sub-title">
          <IxRes>phbb.wizard.label.partOne</IxRes>:
          <IxRes>phbb.wizard.stepSixAction.partOne</IxRes>
        </div>
      </template>

      <RequiredNutrientContent />

      <div v-if="mapCalculating" class="loading-indicator-box bottom-margin">
        <FrsLoadingIndicator loading />
      </div>
      <template #footer>
        <IxButton large submit @click="startCalculation">
          <IxRes>phbb.wizard.button.startCreation</IxRes>
        </IxButton>
        <IxButton large @click="launchMapManagement">
          <IxRes>phbb.wizard.button.launchMapManagement</IxRes>
        </IxButton>
      </template>
    </ContentWrapper>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'
import RequiredNutrientContent from './RequiredNutrientContent'
import ContentWrapper from '@frs/components/phbb/widgets/ContentWrapper'
import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import FormPartMixin from '@components/forms/FormPartMixin'
import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

export default {
  components: {
    IxButton,
    ContentWrapper,
    FrsLoadingIndicator,
    RequiredNutrientContent,
    FrsRouterBackLink
  },
  mixins: [
    FormPartMixin,
    MirrorRouteMixin
  ],
  data () {
    return {
      mapCalculating: false
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapState('fieldRecordSystem/phbb', {
      algorithmType: state => state.data.requiredNutrient.algorithmType,
      soilTesting: state => state.data.requiredNutrient.soilTesting,
      limingInterval: state => state.data.requiredNutrient.limingInterval,
      soilGroupMapId: state => state.data.requiredNutrient.soilGroupMapId,
      clayMapId: state => state.data.requiredNutrient.clayMapId,
      humusMapId: state => state.data.requiredNutrient.humusMapId,
      phMapId: state => state.data.requiredNutrient.phMapId
    }),
    isValid () {
      return this.state === 'success'
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'loadSoilMapsForField'
    ]),
    ...mapActions('fieldRecordSystem/phbb', [
      'createRequiredNutrientMap'
    ]),
    launchMapManagement () {
      this.$router.push({name: this.createParallelRoute('mapManagement')})
    },
    async startCalculation () {
      this.validate()
      await this.$nextTick()

      if (!this.isValid) return

      this.mapCalculating = true
      notifications.info(this.$i18n.translate('phbb.createCaoRequiredNutrientMap.notifications.mapBeingCalculated'))
      try {
        const result = await this.createRequiredNutrientMap()
        if (result.success) {
          notifications.success(this.$i18n.translate('phbb.createCaoRequiredNutrientMap.notifications.success'))
        } else {
          notifications.error(this.$i18n.translate('phbb.createCaoRequiredNutrientMap.notifications.noMapsCreated'))
          console.error(result.rlog)
        }
      } catch (error) {
        console.error(error)
        notifications.error(this.$i18n.translate('phbb.createCaoRequiredNutrientMap.notifications.error'))
      } finally {
        this.mapCalculating = false
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      await vm.loadSoilMapsForField(to.params.fieldId)
    })
  }
}
</script>

<style lang="scss" scoped>
.sub-title {
  font-size: 1em;
  font-weight: lighter;
}

.loading-indicator-box {
  position: relative;
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
}
</style>
