<template lang="html">
  <div class="sub-header">
    <div class="title">
      <slot name="title" />
    </div>
    <div class="description">
      <VPopover
        v-if="$slots.description"
        trigger="hover"
      >
        <span class="description-button">
          <a href="#" @click.prevent>
            <i class="fa fa-question-circle" />
          </a>
        </span>
        <template #popover>
          <slot name="description" />
        </template>
      </VPopover>
    </div>
  </div>
</template>

<script>
import {VPopover} from 'v-tooltip'

export default {
  components: {
    VPopover
  }
}
</script>

<style lang="css" scoped>
.sub-header {
  display: flex;
}

.title {
  font-size: 1.4em;
  margin-right: 4px;
}

.v-popover {
  margin-top: 5px;
}
</style>
