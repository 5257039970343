<template lang="html">
  <div class="content">
    <IxButton :icon="showMap[type] ? 'eye' : 'eye-slash'" :disabled="disabled" @click="toggleMap(type)" />
    <IxSlider v-model="modelProxy" :disabled="disabled || !showMap[type]" />
  </div>
</template>

<script>
import {mapFormFields, modelProxy} from '@helpers/vuex'

import IxButton from '@components/IxButton'
import IxSlider from '@components/IxSlider'

export default {
  components: {
    IxButton,
    IxSlider
  },
  props: {
    value: Number,
    type: String,
    disabled: Boolean
  },
  computed: {
    modelProxy,
    ...mapFormFields('fieldRecordSystem/phbb', [
      'data.requiredNutrient.showMap'
    ])
  },
  methods: {
    toggleMap (type) {
      this.showMap[this.type] = !this.showMap[this.type]
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
}
</style>
