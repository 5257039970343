<template lang="html">
  <div class="content">
    <SubHeader>
      <template #title>
        <IxRes>phbb.create.subHeader.algorithmType</IxRes>
      </template>
      <template #description>
        <IxRes>phbb.create.description.algorithmType_{{ algorithmType === 'vdlufa' ? 'vdlufa' : 'stepless' }}</IxRes>
      </template>
    </SubHeader>

    <BsRadioList
      v-model="algorithmType" :options="['vdlufa', 'stepless']"
      name="algorithmType"
      compact
    >
      <template #default="{option}">
        <IxRes>phbb.create.radio.algorithmType.{{ option === 'vdlufa' ? 'vdlufa' : 'stepless' }}</IxRes>
      </template>
    </BsRadioList>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import BsRadioList from '@components/bootstrap/BsRadioList'

import SubHeader from '../../widgets/SubHeader'

export default {
  components: {
    BsRadioList,
    SubHeader
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/phbb', [
      'data.requiredNutrient.algorithmType'
    ])
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 10px;
}
</style>
