<template lang="html">
  <div class="content">
    <SubHeader>
      <template #title>
        <IxRes>phbb.create.subHeader.surchargeDiscountCalculation</IxRes>
      </template>
      <template #description>
        <IxRes>phbb.create.description.surchargeDiscountCalculation</IxRes>
      </template>
    </SubHeader>

    <div>
      <NumericInput
        v-model="limingInterval"
        :rule="min(1)"
        name="limingInterval"
        required
        integer
      >
        <template #label>
          <IxRes>phbb.create.label.surchargeDiscountCalculation.limingInterval</IxRes>
        </template>
      </NumericInput>
    </div>
    <div>
      <VueSelectize
        v-model="soilTesting" allow-empty
        :options="yearOptions" ordered
        name="soilTesting" required
      >
        <template #label>
          <IxRes>phbb.create.label.surchargeDiscountCalculation.lastSoilTesting</IxRes>
        </template>
      </VueSelectize>
    </div>
  </div>
</template>

<script>
import {rangeRight} from 'lodash'
import {mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import NumericInput from '@components/forms/NumericInput'
import VueSelectize from '@components/VueSelectize'

import SubHeader from '../../widgets/SubHeader'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

export default {
  components: {
    NumericInput,
    SubHeader,
    VueSelectize
  },
  mixins: [StandardRulesMixin],
  computed: {
    ...mapGetters('fieldRecordSystem', [
      'harvestYear'
    ]),
    ...mapFormFields('fieldRecordSystem/phbb', [
      'data.requiredNutrient.limingInterval',
      'data.requiredNutrient.soilTesting'
    ]),
    yearOptions () {
      return rangeRight(this.harvestYear - 6, this.harvestYear + 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 10px;
}
::v-deep .input-field {
  width: 75px;
}
</style>
