<template lang="html">
  <input
    :id="id" ref="range-input"
    :name="name"
    :disabled="disabled"
    type="range"
    :value="value"
    :min="min"
    :max="max"
    @input="onInput($event.target.value)"
  >
</template>

<script>
let counter = 0

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    name: String,
    disabled: Boolean,
    display: Boolean,
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    }
  },
  data () {
    return {
      id: `range-input-${counter++}`
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', this.$i18n.parse(value, 'number'))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
