<template lang="html">
  <div>
    <SubHeader>
      <template #title>
        <IxRes>phbb.create.subHeader.mapsSelection</IxRes>
      </template>
    </SubHeader>

    <template v-if="algorithmType">
      <VueSelectize
        v-model="soilGroupMapId" required
        :options="soilGroupMapIds" :fields="fields"
        allow-empty ordered
        name="SoilGroupMap"
      >
        <template #label>
          <IxRes>phbb.create.selectize.labels.SoilGroupMap</IxRes>
        </template>
      </VueSelectize>
      <TransparencyRegulator v-model="soilGroupTransparency" :disabled="!soilGroupMapId" type="soilGroup" />

      <VueSelectize
        v-model="humusMapId" required
        :options="humusMapIds" :fields="fields"
        allow-empty ordered
        name="HumusMap"
      >
        <template #label>
          <IxRes>phbb.create.selectize.labels.HumusMap</IxRes>
        </template>
      </VueSelectize>
      <TransparencyRegulator v-model="humusMapTransparency" :disabled="!humusMapId" type="humusContent" />

      <VueSelectize
        v-model="phMapId" required
        :options="phMapIds" :fields="fields"
        allow-empty ordered
        name="PhMap"
      >
        <template #label>
          <IxRes>phbb.create.selectize.labels.PhMap</IxRes>
        </template>
      </VueSelectize>
      <TransparencyRegulator v-model="phMapTransparency" :disabled="!phMapId" type="pH" />

      <VueSelectize
        v-if="algorithmType === 'stepless'"
        v-model="clayMapId" required
        :options="clayMapIds" :fields="fields"
        allow-empty ordered
        name="clayMap"
      >
        <template #label>
          <IxRes>phbb.create.selectize.labels.ClayMap</IxRes>
        </template>
      </VueSelectize>
      <TransparencyRegulator
        v-if="algorithmType === 'stepless'"
        v-model="clayMapTransparency"
        :disabled="!clayMapId"
        type="clayContent"
      />
    </template>

    <PhBbMapLayer v-if="showMap['clayContent'] && algorithmType === 'stepless'" :opacity="clayMapTransparency / 100" :map="clayMap" />
    <PhBbMapLayer v-if="showMap['soilGroup']" :opacity="soilGroupTransparency / 100" :map="soilGroupMap" />
    <PhBbMapLayer v-if="showMap['humusContent']" :opacity="humusMapTransparency / 100" :map="humusMap" />
    <PhBbMapLayer v-if="showMap['pH']" :opacity="phMapTransparency / 100" :map="phMap" />
  </div>
</template>

<script>
import {orderBy} from 'lodash'
import {mapState, mapActions, mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'

import PhBbMapLayer from '@frs/components/base/PhBbMapLayer'

import SubHeader from '../../widgets/SubHeader'
import TransparencyRegulator from '../../widgets/TransparencyRegulator'

export default {
  components: {
    VueSelectize,
    SubHeader,
    TransparencyRegulator,
    PhBbMapLayer
  },
  data () {
    return {
      soilGroupTransparency: 75,
      humusMapTransparency: 75,
      phMapTransparency: 75,
      clayMapTransparency: 75
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem/phbb', [
      'soilMaps'
    ]),
    ...mapState('fieldRecordSystem/phbb', {
      showMap: state => state.data.requiredNutrient.showMap
    }),
    ...mapFormFields('fieldRecordSystem/phbb', [
      'data.requiredNutrient.algorithmType',
      'data.requiredNutrient.soilGroupMapId',
      'data.requiredNutrient.clayMapId',
      'data.requiredNutrient.humusMapId',
      'data.requiredNutrient.phMapId'
    ]),
    soilGroupMap () {
      if (!this.soilGroupMapId) return null

      const map = this.soilMaps.find(x => x.id === this.soilGroupMapId)
      if (!map || !map.zones) return null

      return map
    },
    soilGroupMapIds () {
      return orderBy(this.soilMaps.filter(x => x.category === 'soilGroup'), ['generatedAt'], ['desc']).map(map => map.id)
    },
    humusMap () {
      if (!this.humusMapId) return null

      const map = this.soilMaps.find(x => x.id === this.humusMapId)
      if (!map || !map.zones) return null

      return map
    },
    humusMapIds () {
      return orderBy(this.soilMaps.filter(x => x.category === 'humusContent'), ['generatedAt'], ['desc']).map(map => map.id)
    },
    phMap () {
      if (!this.phMapId) return null

      const map = this.soilMaps.find(x => x.id === this.phMapId)
      if (!map || !map.zones) return null

      return map
    },
    phMapIds () {
      return orderBy(this.soilMaps.filter(x => x.category === 'pH'), ['generatedAt'], ['desc']).map(map => map.id)
    },
    clayMap () {
      if (!this.clayMapId) return null

      const map = this.soilMaps.find(x => x.id === this.clayMapId)
      if (!map || !map.zones) return null

      return map
    },
    clayMapIds () {
      return orderBy(this.soilMaps.filter(x => x.category === 'clayContent'), ['generatedAt'], ['desc']).map(map => map.id)
    },
    fields () {
      return {
        value: id => id,
        text: id => this.soilMaps.find(map => map.id === id).name,
        label: id => this.$i18n.format(this.soilMaps.find(map => map.id === id).generatedAt, 'datetime')
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'loadSoilMapDetails'
    ])
  },
  watch: {
    soilGroupMapId: {
      immediate: true,
      async handler (id) {
        if (id) {
          await this.loadSoilMapDetails(id)
          this.soilGroupMapId = id
        }
      }
    },
    humusMapId: {
      immediate: true,
      async handler (id) {
        if (id) {
          await this.loadSoilMapDetails(id)
          this.humusMapId = id
        }
      }
    },
    phMapId: {
      immediate: true,
      async handler (id) {
        if (id) {
          await this.loadSoilMapDetails(id)
          this.phMapId = id
        }
      }
    },
    clayMapId: {
      immediate: true,
      async handler (id) {
        if (id) {
          await this.loadSoilMapDetails(id)
          this.clayMapId = id
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
